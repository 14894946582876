import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Layout from "../components/Layout";
import Headline from "../components/Headline";
import Card from "../components/Card";

export default function Blog() {
  const data = useStaticQuery(graphql`
    {
      allFile(filter: {name: {eq: "type-writer"}}) {
        nodes {
          publicURL
        }
      }
      allContentfulBlogPost(sort: {fields: createdDate, order: DESC}) {
        nodes {
          id
          slug
          headline
          seoDescription
          createdDate
        }
      }
    }
  `);
  const image = data.allFile.nodes[0];
  const blogPosts = data.allContentfulBlogPost.nodes;
  return (
    <Layout
      title="Blog"
      description="Bobby Saul's blog. View articles about some of his interests."
      image={image.publicURL}
    >
      <Headline headlineText='Blog' backgroundImage={image.publicURL} />
      <p>Welcome to my blog. Read some of my articles about my life and interests.</p>
      <div className="Card-Container">
        {blogPosts.map((post) => {
          const path = post.slug[0] === '/' ? `/blog${post.slug}` : `/blog/${post.slug}`
          return <Card key={post.id} title={post.headline} text={post.seoDescription} url={path} date={post.createdDate} />
        })}
      </div>
    </Layout>
  )
}

import { Link } from "gatsby";
import React from "react";
import formatDate from "../functions/formatDate";
import '../styles/card.scss';

export default function Card({ url, title, text, date }) {
  return (
    <div className="Card">
      <div className="Card-Header">
        <strong>{title}</strong>
        <p>{formatDate(date)}</p>
      </div>
      <p>{text}</p>
      <Link to={url}>Read blog</Link>
    </div>
  )
}